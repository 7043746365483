/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { loginAdmin } from "../../core/_requests";
import { useAuth } from "../../core/Auth";
import axios, { AxiosError } from "axios"; // Importar AxiosError

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email("Formato de e-mail inválido")
        .min(3, "Mínimo de 3 caracteres")
        .max(50, "Máximo de 50 caracteres")
        .required("E-mail é obrigatório"),
    password: Yup.string()
        .min(3, "Mínimo de 3 caracteres")
        .max(50, "Máximo de 50 caracteres")
        .required("Senha é obrigatória"),
});

const initialValues = {
    email: "felipemrvieira@gmail.com",
    password: "123123",
};

export function Login() {
    const [loading, setLoading] = useState(false);
    const { saveAuth, setCurrentUser } = useAuth();

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);

            try {
                const response = await loginAdmin(
                    values.email,
                    values.password
                );
                const { data, headers } = response;

                if (data.success === false && data.errors) {
                    // Exibe a mensagem de erro retornada pela API
                    setStatus(data.errors[0]); // Pega a primeira mensagem de erro
                } else {
                    const { data: user } = data;
                    setCurrentUser(user);
                    saveAuth({
                        authorization: headers["authorization"],
                        refreshToken: undefined,
                    });
                }

                setSubmitting(false);
                setLoading(false);
            } catch (error) {
                // Verifica se o erro é uma instância de AxiosError
                if (axios.isAxiosError(error)) {
                    // Verifica se o erro tem uma resposta
                    if (
                        error.response &&
                        error.response.status === 401 &&
                        error.response.data.errors
                    ) {
                        setStatus(error.response.data.errors[0]); // Exibe a mensagem de erro da API
                    } else {
                        setStatus(
                            "Ocorreu um erro inesperado. Tente novamente."
                        );
                    }
                } else {
                    setStatus("Ocorreu um erro inesperado. Tente novamente.");
                }

                setSubmitting(false);
                setLoading(false);
            }
        },
    });

    return (
        <form
            className="form w-100 d-flex flex-column"
            onSubmit={formik.handleSubmit}
            noValidate
            id="kt_login_signin_form"
        >
            {/* begin::Heading */}
            <div className="text-center mb-11">
                <h1 className="text-dark fw-bolder mb-3">Entrar</h1>
            </div>
            {/* begin::Heading */}

            {formik.status ? (
                <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">
                        {formik.status}
                    </div>
                </div>
            ) : null}

            {/* begin::Form group */}
            <div className="fv-row mb-8">
                <label className="form-label fs-6 fw-bolder text-dark">
                    Email
                </label>
                <input
                    placeholder="Email"
                    {...formik.getFieldProps("email")}
                    className={clsx(
                        "form-control bg-transparent",
                        {
                            "is-invalid":
                                formik.touched.email && formik.errors.email,
                        },
                        {
                            "is-valid":
                                formik.touched.email && !formik.errors.email,
                        }
                    )}
                    type="email"
                    name="email"
                    autoComplete="off"
                />
                {formik.touched.email && formik.errors.email && (
                    <div className="fv-plugins-message-container">
                        <span role="alert">{formik.errors.email}</span>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className="fv-row mb-3">
                <label className="form-label fw-bolder text-dark fs-6 mb-0">
                    Senha
                </label>
                <input
                    type="password"
                    autoComplete="off"
                    {...formik.getFieldProps("password")}
                    className={clsx(
                        "form-control bg-transparent",
                        {
                            "is-invalid":
                                formik.touched.password &&
                                formik.errors.password,
                        },
                        {
                            "is-valid":
                                formik.touched.password &&
                                !formik.errors.password,
                        }
                    )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                            <span role="alert">{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Wrapper */}
            <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                <div />

                {/* begin::Link */}
                <Link to="/auth/forgot-password" className="link-primary">
                    Esqueceu a senha?
                </Link>
                {/* end::Link */}
            </div>
            {/* end::Wrapper */}

            {/* begin::Action */}
            <div className="d-grid mb-10">
                <button
                    type="submit"
                    id="kt_sign_in_submit"
                    className="btn btn-primary"
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && (
                        <span className="indicator-label">Continue</span>
                    )}
                    {loading && (
                        <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                        >
                            Por favor, aguarde...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    )}
                </button>
            </div>
            {/* end::Action */}

            <div className="text-gray-500 text-center fw-semibold fs-6">
                Ainda não é membro?{" "}
                <Link to="/admin/auth/registration" className="link-primary">
                    Cadastre-se
                </Link>
            </div>
        </form>
    );
}
