import { Route, Routes } from "react-router-dom";
import { Registration } from "./components/Admin/Registration";
import { ForgotPassword } from "./components/Admin/ForgotPassword";
import { Login } from "./components/Admin/Login";
import { AdminAuthLayout } from "./AdminAuthLayout";
import { Confirmation } from "./components/Admin/Confirmation";

const AdminAuthPage = () => (
    <Routes>
        <Route element={<AdminAuthLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="registration" element={<Registration />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="confirm" element={<Confirmation />} />
            <Route index element={<Registration />} />
        </Route>
    </Routes>
);

export { AdminAuthPage };
