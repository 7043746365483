import { useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

const AdminAuthLayout = () => {
    useEffect(() => {
        const root = document.getElementById("root");
        if (root) {
            root.style.height = "100%";
        }
        return () => {
            if (root) {
                root.style.height = "auto";
            }
        };
    }, []);

    return (
        <div className="d-flex flex-column flex-lg-row flex-column-fluid h-100">
            {/* begin::Body */}
            <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
                {/* begin::Form */}
                <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                    {/* <Link to="/" className="mb-20">
                        <img
                            alt="Logo"
                            src={toAbsoluteUrl("/media/logos/logo-branco.png")}
                            className="h-50px"
                        />
                    </Link> */}
                    {/* begin::Wrapper */}
                    <div className="w-lg-500px p-10">
                        <Outlet />
                    </div>
                    {/* end::Wrapper */}
                </div>
                {/* end::Form */}

                {/* begin::Footer */}
                <div className="d-flex flex-center flex-wrap px-5">
                    {/* begin::Links */}
                    <div className="d-flex fw-semibold text-primary fs-base">
                        <a href="#" className="px-5" target="_blank">
                            Termos
                        </a>

                        <a href="#" className="px-5" target="_blank">
                            Planos
                        </a>

                        <a href="#" className="px-5" target="_blank">
                            Contato
                        </a>
                    </div>
                    {/* end::Links */}
                </div>
                {/* end::Footer */}
            </div>
            {/* end::Body */}

            {/* begin::Aside */}
            <div className="d-flex flex-lg-row-fluid w-lg-50 order-1 order-lg-2 position-relative">
                {/* begin::Video */}
                <video
                    className="position-absolute w-100 h-100 object-fit-cover"
                    src={toAbsoluteUrl("/media/videos/bg_video.mp4")}
                    autoPlay
                    loop
                    muted
                />
                {/* end::Video */}

                {/* begin::Content */}
                <div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100 position-relative z-index-2">
                    {/* begin::Logo */}
                    <Link to="/" className="mb-10">
                        <img
                            alt="Logo"
                            src={toAbsoluteUrl("/media/logos/logo-branco.png")}
                            className="h-50px"
                        />
                    </Link>
                    {/* end::Logo */}

                    {/* begin::Image */}
                    {/* <img
                        className="mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
                        src={toAbsoluteUrl("/media/misc/auth-screens.png")}
                        alt=""
                    /> */}
                    {/* end::Image */}

                    {/* begin::Title */}
                    <h1 className="text-white fs-1qx fw-bolder text-center mb-7">
                        Gestão de Academias Eficiente e Inovadora
                    </h1>
                    {/* end::Title */}

                    {/* begin::Text */}
                    {/* <div className="text-white fs-base text-center">
                        Este app revoluciona o gerenciamento de academias com
                        uma solução whitelabel personalizada. <br />
                        Com integração de inteligência artificial, ele otimiza a
                        gestão de treinos e operações diárias, trazendo mais
                        eficiência e produtividade.
                    </div> */}
                    {/* end::Text */}
                </div>
                {/* end::Content */}
            </div>
            {/* end::Aside */}
        </div>
    );
};

export { AdminAuthLayout };
