import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { admin_register } from "../../core/_requests";
import { Link } from "react-router-dom";
import { PasswordMeterComponent } from "../../../../../_metronic/assets/ts/components";
import { useAuth } from "../../core/Auth";
import axios, { AxiosError } from "axios"; // Importar AxiosError

const initialValues = {
    email: "",
};

const registrationSchema = Yup.object().shape({
    email: Yup.string()
        .email("Formato de email errado")
        .min(3, "Mínimo 3 caracteres")
        .max(50, "Máximo 50 caracteres")
        .required("Email é obrigatório"),
});

export function Registration() {
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(""); // Estado para a mensagem de sucesso
    const [isRegistered, setIsRegistered] = useState(false); // Estado para controlar se o formulário deve ser exibido
    const { saveAuth } = useAuth();

    const formik = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);

            try {
                const response = await admin_register(values.email);
                const { data } = response;

                if (data.success === false && data.errors) {
                    // Exibe a mensagem de erro retornada pela API
                    setStatus(data.errors[0]); // Pega a primeira mensagem de erro
                } else {
                    setSuccessMessage(
                        "Registro bem-sucedido! Cheque seu e-mail para ativar sua conta e criar uma senha."
                    );
                    setIsRegistered(true);
                }

                setSubmitting(false);
                setLoading(false);
            } catch (error) {
                // Verifica se o erro é uma instância de AxiosError
                if (axios.isAxiosError(error)) {
                    // Verifica se o erro tem uma resposta da API
                    if (error.response && error.response.data.errors) {
                        setStatus(error.response.data.errors[0]); // Exibe a mensagem de erro da API
                    } else {
                        setStatus(
                            "Ocorreu um erro inesperado. Tente novamente."
                        );
                    }
                } else {
                    setStatus("Ocorreu um erro inesperado. Tente novamente.");
                }

                setSubmitting(false);
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        PasswordMeterComponent.bootstrap();
    }, []);

    return (
        <>
            {/* Se o registro foi bem-sucedido, exibe a mensagem de sucesso */}
            {isRegistered ? (
                <div className="text-center mb-11">
                    <h1 className="text-dark fw-bolder mb-3">
                        Registro bem-sucedido!
                    </h1>
                    <p className="text-gray-500 fw-semibold fs-6">
                        {successMessage}
                    </p>
                    <Link to="/auth/login">
                        <button className="btn btn-lg btn-primary w-100 mb-5">
                            Ir para Login
                        </button>
                    </Link>
                </div>
            ) : (
                <form
                    className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework flex-column"
                    noValidate
                    id="kt_login_signup_form"
                    onSubmit={formik.handleSubmit}
                >
                    {/* begin::Heading */}
                    <div className="text-center mb-11">
                        <h1 className="text-dark fw-bolder mb-3">
                            Cadastre-se
                        </h1>
                        <div className="text-gray-500 fw-semibold fs-6">
                            E tenha a sua academia gerenciada em instantes
                        </div>
                    </div>
                    {/* end::Heading */}

                    {/* Exibe a mensagem de erro */}
                    {formik.status && (
                        <div className="mb-lg-15 alert alert-danger">
                            <div className="alert-text font-weight-bold">
                                {formik.status}
                            </div>
                        </div>
                    )}

                    {/* begin::Form group Email */}
                    <div className="fv-row mb-4 w-100">
                        <label className="form-label fw-bolder text-dark fs-6">
                            Email
                        </label>
                        <input
                            placeholder="Email"
                            type="email"
                            autoComplete="off"
                            {...formik.getFieldProps("email")}
                            className={clsx(
                                "form-control bg-transparent",
                                {
                                    "is-invalid":
                                        formik.touched.email &&
                                        formik.errors.email,
                                },
                                {
                                    "is-valid":
                                        formik.touched.email &&
                                        !formik.errors.email,
                                }
                            )}
                        />
                        {formik.touched.email && formik.errors.email && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">
                                        {formik.errors.email}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group */}
                    <div className="text-center">
                        <button
                            type="submit"
                            id="kt_sign_up_submit"
                            className="btn btn-lg btn-primary w-100 mb-5"
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            {!loading && (
                                <span className="indicator-label">Enviar</span>
                            )}
                            {loading && (
                                <span
                                    className="indicator-progress"
                                    style={{ display: "block" }}
                                >
                                    Por favor aguarde...{" "}
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            )}
                        </button>
                        <Link to="/auth/login">
                            <button
                                type="button"
                                id="kt_login_signup_form_cancel_button"
                                className="btn btn-lg btn-light-primary w-100 mb-5"
                            >
                                Cancelar
                            </button>
                        </Link>
                    </div>
                    {/* end::Form group */}
                    <div className="text-gray-500 text-center fw-semibold fs-6">
                        Já é membro?{" "}
                        <Link to="/admin/auth/login" className="link-primary">
                            Entre
                        </Link>
                    </div>
                </form>
            )}
        </>
    );
}
