/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import {
    customer_confirm,
    customer_update_details,
} from "../../core/_requests";
import { Link, useSearchParams } from "react-router-dom";
import { PasswordMeterComponent } from "../../../../../_metronic/assets/ts/components";
import { useAuth } from "../../core/Auth";

const initialValues = {
    password: "",
    passwordconfirmation: "",
    acceptTerms: false,
};

const registrationSchema = Yup.object().shape({
    password: Yup.string()
        .min(6, "Mínimo 6 caracteres")
        .max(20, "Máximo 20 caracteres")
        .required("Senha é obrigatório"),
    passwordconfirmation: Yup.string()
        .min(6, "Mínimo 6 caracteres")
        .max(20, "Máximo 20 caracteres")
        .required("Confirmação de senha é obrigatório")
        .oneOf(
            [Yup.ref("password")],
            "Senha e Confirmação de Senha não coincidem"
        ),
    acceptTerms: Yup.bool().required("Você deve aceitar os termos"),
});

export function Confirmation() {
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const [successMessage, setSuccessMessage] = useState(""); // Estado para armazenar a mensagem de sucesso
    const [errorMessage, setErrorMessage] = useState(""); // Estado para armazenar a mensagem de sucesso
    const [isConfirmed, setIsConfirmed] = useState(false); // Estado para controlar a exibição do formulário
    const [isUpdated, setIsUpdated] = useState(false); // Estado para controlar a exibição do formulário

    const token = searchParams.get("confirmation_token");
    const tenant = searchParams.get("tenant");

    const formik = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            try {
                if (!token) {
                    throw new Error("Token de confirmação inválido");
                }

                const resp = await customer_update_details(
                    token,
                    values.password,
                    values.passwordconfirmation,
                    tenant
                );

                if (resp.data.success) {
                    setSuccessMessage(resp.data.message); // Atualiza a mensagem de sucesso
                    setIsUpdated(true); // Define que a confirmação foi bem-sucedida
                    setStatus(""); // Limpa o status de erro
                } else {
                    setStatus(resp.data.message || "Erro ao confirmar a conta");
                }

                setLoading(false);
                setSubmitting(false);
            } catch (error) {
                console.error(error);
                setStatus("Detalhes de confirmação incorretos");
                setSubmitting(false);
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        const token = searchParams.get("confirmation_token");
        if (token) {
            customer_confirm(token, tenant)
                .then((resp) => {
                    console.log(resp);
                    if (resp.data.success) {
                        setIsConfirmed(true);
                        setSuccessMessage(resp.data.message);
                    } else {
                        setIsConfirmed(false);
                        setSuccessMessage(
                            resp.data.message || "Erro ao confirmar token."
                        );
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setErrorMessage("Erro ao confirmar token.");
                });
        }
    }, [searchParams]);

    useEffect(() => {
        PasswordMeterComponent.bootstrap();
    }, []);

    return (
        <>
            {/* Se a confirmação for bem-sucedida, exibe o botão de login */}
            {isUpdated ? (
                <div className="text-center mb-11">
                    <h1 className="text-dark fw-bolder mb-3">
                        Conta Confirmada
                    </h1>
                    <p className="text-gray-500 fw-semibold fs-6">
                        {successMessage}
                    </p>
                    <Link to="/customer/auth/login">
                        <button className="btn btn-lg btn-primary w-100 mb-5">
                            Ir para Login
                        </button>
                    </Link>
                </div>
            ) : (
                <form
                    className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework flex-column"
                    noValidate
                    id="kt_login_signup_form"
                    onSubmit={formik.handleSubmit}
                >
                    {/* begin::Heading */}
                    <div className="text-center mb-11">
                        <h1 className="text-dark fw-bolder mb-3">
                            Confirmação
                        </h1>
                        <div className="text-gray-500 fw-semibold fs-6">
                            Confirme a criação da sua conta{" "}
                        </div>
                    </div>
                    {/* end::Heading */}

                    {(formik.status || errorMessage) && (
                        <div className="mb-lg-15 alert alert-danger">
                            <div className="alert-text font-weight-bold">
                                {formik.status || errorMessage}
                            </div>
                        </div>
                    )}

                    {/* Exibe a mensagem de sucesso */}
                    {successMessage && (
                        <div className="mb-lg-15 alert alert-success">
                            <div className="alert-text font-weight-bold">
                                {successMessage}
                            </div>
                        </div>
                    )}

                    {/* begin::Form group Password */}
                    <div
                        className="fv-row mb-4 w-100"
                        data-kt-password-meter="true"
                    >
                        <div className="mb-1">
                            <label className="form-label fw-bolder text-dark fs-6">
                                Senha
                            </label>
                            <div className="position-relative mb-3">
                                <input
                                    type="password"
                                    placeholder="Senha"
                                    autoComplete="off"
                                    {...formik.getFieldProps("password")}
                                    className={clsx(
                                        "form-control bg-transparent",
                                        {
                                            "is-invalid":
                                                formik.touched.password &&
                                                formik.errors.password,
                                        },
                                        {
                                            "is-valid":
                                                formik.touched.password &&
                                                !formik.errors.password,
                                        }
                                    )}
                                />
                                {formik.touched.password &&
                                    formik.errors.password && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">
                                                    {formik.errors.password}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                            </div>
                            <div
                                className="d-flex align-items-center mb-3"
                                data-kt-password-meter-control="highlight"
                            >
                                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                            </div>
                        </div>
                        <div className="text-muted">
                            Use 6 ou mais caracteres com letras e números.
                        </div>
                    </div>

                    <div className="fv-row mb-5 w-100">
                        <label className="form-label fw-bolder text-dark fs-6">
                            Confirme a Senha
                        </label>
                        <input
                            type="password"
                            placeholder="Confirmação de senha"
                            autoComplete="off"
                            {...formik.getFieldProps("passwordconfirmation")}
                            className={clsx(
                                "form-control bg-transparent",
                                {
                                    "is-invalid":
                                        formik.touched.passwordconfirmation &&
                                        formik.errors.passwordconfirmation,
                                },
                                {
                                    "is-valid":
                                        formik.touched.passwordconfirmation &&
                                        !formik.errors.passwordconfirmation,
                                }
                            )}
                        />
                        {formik.touched.passwordconfirmation &&
                            formik.errors.passwordconfirmation && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        <span role="alert">
                                            {formik.errors.passwordconfirmation}
                                        </span>
                                    </div>
                                </div>
                            )}
                    </div>

                    <div className="fv-row mb-4 w-100">
                        <label
                            className="form-check form-check-inline"
                            htmlFor="kt_login_toc_agree"
                        >
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="kt_login_toc_agree"
                                {...formik.getFieldProps("acceptTerms")}
                            />
                            <span>
                                Aceito os{" "}
                                <a
                                    href="https://keenthemes.com/metronic/?page=faq"
                                    target="_blank"
                                    className="ms-1 link-primary"
                                >
                                    Termos
                                </a>
                                .
                            </span>
                        </label>
                        {formik.touched.acceptTerms &&
                            formik.errors.acceptTerms && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        <span role="alert">
                                            {formik.errors.acceptTerms}
                                        </span>
                                    </div>
                                </div>
                            )}
                    </div>

                    <div className="text-center">
                        <button
                            type="submit"
                            id="kt_sign_up_submit"
                            className="btn btn-lg btn-primary w-100 mb-5"
                            disabled={
                                formik.isSubmitting ||
                                !formik.isValid ||
                                !formik.values.acceptTerms
                            }
                        >
                            {!loading && (
                                <span className="indicator-label">
                                    Confirmar
                                </span>
                            )}
                            {loading && (
                                <span
                                    className="indicator-progress"
                                    style={{ display: "block" }}
                                >
                                    Por favor aguarde...{" "}
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            )}
                        </button>
                        <Link to="/auth/login">
                            <button
                                type="button"
                                id="kt_login_signup_form_cancel_button"
                                className="btn btn-lg btn-light-primary w-100 mb-5"
                            >
                                Cancelar
                            </button>
                        </Link>
                    </div>
                </form>
            )}
        </>
    );
}
