import { Route, Routes } from "react-router-dom";
import { Registration } from "./components/Customer/Registration";
import { ForgotPassword } from "./components/Customer/ForgotPassword";
import { Login } from "./components/Customer/Login";
import { CustomerAuthLayout } from "./CustomerAuthLayout";
import { Confirmation } from "./components/Customer/Confirmation";

const CustomerAuthPage = () => (
    <Routes>
        <Route element={<CustomerAuthLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="registration" element={<Registration />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="confirm" element={<Confirmation />} />
            <Route index element={<Registration />} />
        </Route>
    </Routes>
);

export { CustomerAuthPage };
