import axios from "axios";
import { AuthModel, UserApiResponse, UserModel } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;
export const GET_USER_BY_ACCESSTOKEN_URL = `/me`;
export const SUPER_ADMIN_LOGIN_URL = `/super_admin_auth/sign_in`;

export const ADMIN_CONFIRM_URL = `/admin_auth/confirmation`;
export const ADMIN_LOGIN_URL = `/admin_auth/sign_in`;
export const ADMIN_REGISTER_URL = `/admins/create`;
export const ADMIN_UPDATE_URL = `/admins/update`;

export const CUSTOMER_CONFIRM_URL = `/customer_auth/confirmation`;
export const CUSTOMER_UPDATE_URL = `/customers/update`;

export const REQUEST_PASSWORD_URL = `/forgot_password`;

// Server should return AuthModel
export function loginSuperAdmin(email: string, password: string) {
    return axios.post<UserApiResponse>(SUPER_ADMIN_LOGIN_URL, {
        email,
        password,
    });
}
export function loginAdmin(email: string, password: string) {
    return axios.post<UserApiResponse>(ADMIN_LOGIN_URL, {
        email,
        password,
    });
}

// Server should return AuthModel
export function admin_register(email: string) {
    return axios.post(`${ADMIN_REGISTER_URL}`, {
        email,
    });
}

export function customer_register(
    name: string,
    email: string,
    cpf: string,
    phone: string
) {
    return axios.post(`${ADMIN_REGISTER_URL}`, {
        name,
        email,
        cpf,
        phone,
    });
}

export function customer_update_details(
    token,
    password,
    password_confirmation,
    tenant
) {
    return axios.patch(`${CUSTOMER_UPDATE_URL}?tenant=${tenant}`, {
        confirmation_token: token,
        password: password,
        password_confirmation: password_confirmation,
    });
}

export function customer_confirm(token, tenant) {
    return axios.post(`${CUSTOMER_CONFIRM_URL}?tenant=${tenant}`, {
        confirmation_token: token,
    });
}

export function admin_confirm(token) {
    return axios.post(`${ADMIN_CONFIRM_URL}`, {
        confirmation_token: token,
    });
}

export function admin_update_details(
    token,
    name,
    cpf,
    phone,
    password,
    password_confirmation
) {
    return axios.patch(`${ADMIN_UPDATE_URL}`, {
        confirmation_token: token,
        name,
        cpf,
        phone,
        password,
        password_confirmation,
    });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
        email,
    });
}

export function getUserByToken(token: string) {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    return axios.get<UserApiResponse>(GET_USER_BY_ACCESSTOKEN_URL, config);
}
